jQuery(document).ready(function($) {
	
	"use strict";


						setThumbHeight();

						$( window ).resize(function() {
							setThumbHeight();
						});

						function setThumbHeight() {
							var thumb = $(document).find('.thumb-bg');
							thumb.css('max-height', thumb.width());
						}


	
	function pedareParallaxRows() {
      var vcSkrollrOptions, callSkrollInit = !1;
      return window.vcParallaxSkroll && window.vcParallaxSkroll.destroy(), $(".vc_parallax-inner").remove(), $("[data-5p-top-bottom]").removeAttr("data-5p-top-bottom data-30p-top-bottom"), $("[data-vc-parallax]").each(function() {
        var skrollrSpeed, skrollrSize, skrollrStart, skrollrEnd, $parallaxElement, parallaxImage, youtubeId;
        callSkrollInit = !0, "on" === $(this).data("vcParallaxOFade") && $(this).children().attr("data-5p-top-bottom", "opacity:0;").attr("data-30p-top-bottom", "opacity:1;"), skrollrSize = 100 * $(this).data("vcParallax"), $parallaxElement = $("<div />").addClass("vc_parallax-inner").appendTo($(this)), $parallaxElement.height(skrollrSize + "%"), parallaxImage = $(this).data("vcParallaxImage"), youtubeId = vcExtractYoutubeId(parallaxImage), youtubeId ? insertYoutubeVideoAsBackground($parallaxElement, youtubeId) : "undefined" != typeof parallaxImage && $parallaxElement.css("background-image", "url(" + parallaxImage + ")"), skrollrSpeed = skrollrSize - 100, skrollrStart = -skrollrSpeed, skrollrEnd = 0, $parallaxElement.attr("data-bottom-top", "top: " + skrollrStart + "%;").attr("data-top-bottom", "top: " + skrollrEnd + "%;")
      }), callSkrollInit && window.skrollr ? (vcSkrollrOptions = {
        forceHeight: !1,
        smoothScrolling: !1,
        mobileCheck: function() {
          return !1
        }
      }, window.vcParallaxSkroll = skrollr.init(vcSkrollrOptions), window.vcParallaxSkroll) : !1
    }
		
	// BLOG PAGE
	$(document).on('click', '.load-more-post .button', function(e) {
		e.preventDefault()
		var $this = $(this).parent().parent()
		var $thisBtn = $(this).parent()
		$thisBtn.slideUp()
		if (!$('.common-loop-post-container').hasClass('loading')) {
			//setTimeout(function() {
				
				$('.load-more-post .loader').slideDown()
				
				setTimeout(function() {
					$.ajax({
						type: 'POST',
						dataType: "json",
						url: loadmore.ajaxurl,
						data: {
							blog_category: $('input[name="filter-category"]:checked').val(),
							blog_sort: $('#sort-blog').val(),
							blog_date_start: $('.date_filter_from').val(),
							blog_date_end: $('.date_filter_to').val(),
							blog_search: $('#_s').val(),
							paging: $($this).find('#paging-handler').attr('data-value'),
							exclude: $($this).find('#paging-handler').attr('data-exclude'),
							security: loadmore.nonce,
							action: 'pedare_filter_sort_type'
						},
						beforeSend: function(){
							$('.common-loop-post-container').addClass('loading')
						},
						success: function(data) {
							$('.common-loop-post-container').append(data.html)
							pedareParallaxRows()
							if ($(document).find('.no-more-listing').length > 0) {
								setTimeout(function() {
									$(document).find('.no-more-listing').fadeOut()
								}, 1000)
							}
							$this.remove()
							$('.page-template-template-blog .blog_tile').matchHeight({byRow: true});
							$('.common-loop-post-container').removeClass('loading')
						}
					});
				}, 500)
			//}, 500)
		}
	});
	

	
	$('.blog-filter input[name="filter-category"]').change(function(e) {
		var $this = $(this)
		var originalVal = $(this).val()

		if($this.val() == 'clear'){
				e.preventDefault()

		
		
				if( ($('#sort-blog').val() == '' || $('#sort-blog').val() == null) && ($('input[name="filter-category"]:checked').val() == '' || $('input[name="filter-category"]:checked').val() == null)  ){
					$('body').addClass('reset-filter')
				}

		
		}
		
		$('.common-loop-post-container').addClass('loading')
		setTimeout(function() {
			$.ajax({
				type: 'POST',
				dataType: "json",
				url: loadmore.ajaxurl,
				data: {
					blog_category: $('input[name="filter-category"]:checked').val(),
							blog_sort: $('#sort-blog').val(),
							blog_date_start: $('.date_filter_from:visible').val(),
							blog_date_end: $('.date_filter_to:visible').val(),
							blog_search: $('._s:visible').val(),
							is_clear: originalVal == 'clear' ? true : false,
							paging: 1,
							exclude:'', // show all
							security: loadmore.nonce,
							action: 'pedare_filter_sort_type'
				},

				beforeSend: function(){
							$('.featured-post.loop-post-container').slideUp()
							$('.common-loop-post-container article').remove()
							$('.load-more-post .loader').slideDown()
							$('.load-more-post .loadmore-btn').slideUp()
						},
				success: function(data) {
					$('.common-loop-post-container').html(data.html)
					pedareParallaxRows()
					if ($(document).find('.no-more-listing').length > 0) {
						setTimeout(function() {
							$(document).find('.no-more-listing').fadeOut()
						}, 1000)
					}
					$('.page-template-template-blog .blog_tile').matchHeight({byRow: true});
					$('.common-loop-post-container').removeClass('loading')
					
					if($('body').hasClass('reset-filter')){
						$('.featured-post.loop-post-container').slideDown()
						$('body').removeClass('reset-filter')
					}
					if(data.is_clear == 'false'){
						if($this.find('option[value="clear"]').length < 1){
							$this.append('<option value="clear">Clear This Filter</option>')
						}
					}else{
						$this.find('option[value="clear"]').remove()
					}
					
					setThumbHeight();
					
					console.log(data.is_clear)
					
					
					
				}
			});
		}, 500)
	})
	
	// date picker
	function checkDatepickerAndSubmit(){
		var emptyDate = $('.datepicker:visible').filter(function() {
				return !this.value;
		}).length
		console.log(emptyDate)
		if(emptyDate<1){
			$('input[name="filter-category"]').trigger('change')
		} 
	}
	
	if($(".date_filter_from").length > 0){
	$(".date_filter_from:visible").datepicker({
        dateFormat: "dd-MM-yy",
        maxDate: 0,
        onSelect: function () {
            var date_filter_to = $('.date_filter_to:visible');
            var selectedFrom  = $(this).datepicker('getDate');   
            date_filter_to.datepicker('option', 'minDate', selectedFrom);
						date_filter_to.datepicker('option', 'maxDate', 0);						
						checkDatepickerAndSubmit()
						
        },

    });
    $('.date_filter_to:visible').datepicker({
        dateFormat: "dd-MM-yy",
				onSelect: function () {
            
						checkDatepickerAndSubmit()
						
        }
    });
	}// if blog parent
		
		// if search
		$('form#blog-filter, form#blog-slide-filter').submit(function(e){
			e.preventDefault()
			
			$(this).find('input[name="filter-category"]:visible').trigger('change')

		})
		
		
		// RESET FILTER
		$('.blog-filter a.clear-filter').click(function(e){
			e.preventDefault()
			$('.blog-filter input[type="radio"]').prop('checked', false);
			$('.blog-filter input[type="text"]').val('');
			$('.blog-filter select').val('')
			$('.load-more-post .loadmore-btn').slideUp()
			
			if(!$('.common-loop-post-container').hasClass('loading')){
				$('input[name="filter-category"]').trigger('change')
			}
			
			$('body').addClass('reset-filter')
		})
		
		// RESET ARTICLE / CATEGORY
		$('.blog-filter a.clear-article').click(function(e){
			e.preventDefault()
			$('.blog-filter input[type="radio"]').prop('checked', false);
			if(!$('.common-loop-post-container').hasClass('loading')){
				$('input[name="filter-category"]').trigger('change')
			}
		})
		
		// ON CHANGE INPUT SEARCH
		$('.blog-filter input._s').change(function(e){
			e.preventDefault()
			$('.blog-filter input[type="radio"]').prop('checked', false);
			if(!$('.common-loop-post-container').hasClass('loading')){
				$('input[name="filter-category"]').trigger('change')
			}
		})
		
		// close filter (mobile)
		$('a.close-filter-mobile,.blog-filter-mobile a.filter-results').click(function(e){
			e.preventDefault()
			$('body').toggleClass('filter-open')
		})
		
		
		
		
		
		
		// SEARCH PAGE
	$(document).on('inview', '.load-more-post.load-more-search', function(event, isInView) {
		var $this = $(this)
		if (isInView && !$('.common-loop-post-container').hasClass('loading')) {
			setTimeout(function() {
				$('.load-more-post .loader').slideDown()
				setTimeout(function() {
					$.ajax({
						type: 'POST',
						dataType: "json",
						url: loadmore.ajaxurl,
						data: {
							blog_search: $($this).find('#paging-handler').attr('data-search'),
							paging: $($this).find('#paging-handler').attr('data-value'),
							security: loadmore.nonce,
							action: 'pedare_load_more_search'
						},
						beforeSend: function(){
							$('.page-template-template-blog .blog_tile').matchHeight({byRow: true});
							$('.common-loop-post-container').addClass('loading')
						},
						error: function(z,y,x){
							console.log(z,y,x)
						},
						success: function(data) {
							$('.common-loop-post-container').append(data.html)
							pedareParallaxRows()
							if ($(document).find('.no-more-listing').length > 0) {
								setTimeout(function() {
									$(document).find('.no-more-listing').fadeOut()
								}, 1000)
							}
							$this.remove()
							console.log(data.arg)
							$('.common-loop-post-container').removeClass('loading')
						}
					});
				}, 500)
			}, 500)
		}
	});
	
	// clear search filter
	$('.search-results a.clear-filter.reset-search').click(function(e){
		e.preventDefault();
		$('.common-loop-post-container.search-results-container,.search-results-reset').slideUp()
		$('.search-results-form').slideDown()
		$('h1.search-result-title').text('New Search')
	
	})
		
		

	
	
})